<template>
  <div class="paintingModalBackground">
    <div class="modalContent">
      <div class="modal-image">
        <img :src="painting.imageFile" loading="lazy"/>
      </div>
      <div class="modalDescription" v-if="!isOrder && !isContact">
        <h1 v-if="painting.name">{{ painting.name }}</h1>
        <h2 v-if="painting.displayName">by {{ painting.displayName }}</h2>
        <h2 v-if="painting.phoneNumber">Phone {{ painting.phoneNumber }}</h2>
        <br>
        <div v-if="painting.description">{{ painting.description }}</div>
        <div>
          <ul>
            <li v-if="painting.technique">Technique: {{ painting.technique }}</li>
            <li v-if="painting.size.width && painting.size.height">
              Size: {{ painting.size.width }} X {{ painting.size.height }} {{ painting.size.unit}}
            </li>
            <li>
              <span v-if="painting.isFramed">Framed</span>
              <span v-else>Not Framed</span>
            </li>
            <li v-if="painting.price.isPriceShown && painting.price.value">Price: {{ painting.price.value }} {{ painting.price.currency }}</li>
            <li v-if="painting.isSold">Sold</li>
          </ul>
        </div>
        <div id="cta" v-if="!isOrder && !isContact && !painting.isSold">
          <buttonCustom class="btnGroup" v-if="painting.price.isPriceShown && painting.price.value" @click.native="isOrder = true" :button="'mimigray'" :icon="'cart'" :text="'Agree on the Price'"/>
          <buttonCustom class="btnGroup" @click.native="isContact = true" :button="'mimigray'" :icon="'contact'" :text="'Contact Artist'"/>
        </div>
      </div>
      <!-- form -->
      <painting-form v-if="isContact || isOrder" :isContact="isContact" :isOrder="isOrder" :painting="painting" @goBack="reset"/>

      <buttonCustom @click.native="onCancel" :button="'close'" :icon="'cancel'"/>
    </div>
  </div>
</template>

<script>
import PaintingContact from '@/components/PaintingContact'

export default {
  components: {
    'painting-form': PaintingContact
  },
  name: 'paintingModal',
  data () {
    return {
      isSent: false,
      isOrder: false,
      isContact: false
    }
  },
  props: {
    clickedPainting: Object,
    id: String
  },
  computed: {
    painting () {
      if (this.clickedPainting) {
        return this.clickedPainting
      } else {
        // direct hit via url or share, get painting
        const id = this.$route.params.id
        return this.paintings.find(el => el.id === id)
      }
    }
  },
  methods: {
    onCancel () {
      this.$router.push({ path: '/' })
    },
    reset () {
      this.isOrder = false
      this.isContact = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
  .paintingModalBackground {
    position: fixed;
    z-index: 3000;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(1, 1, 1, .8);
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-x: hidden;
    background-color: $colorMimiInnerContent;
    position: absolute;
    width: 90vw;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @media screen and (min-width: 860px) {
      flex-direction: row;
    }
  }

  .modal-image {
    flex: 1 0 auto;
    background-color: $colorMimiOuterContent;
    display: flex;
    min-height: 300px;
    img {
      flex: 1 0 auto;
      max-width: 100%;
      height: auto;
      object-fit: contain;
      margin: 0;
      @media screen and (min-width: 860px) {
        min-height: 500px;
      }
    }
    @media screen and (min-width: 860px) {
      flex: 1 0 70%;
    }
  }

  .modalDescription {
    flex: 1 0 35%;
    padding: 1em;
    margin-top: 30px;
    font-size: 1em;
    color: $colorMimiText;
    @media screen and (min-width: 800px) {
      flex: 1 0 30%;
    }
    * {
      font-size: 20px;
    }
    h1 {
      font-size: 2em;
      margin-bottom: .4em;
    }
    h2 {
      font-size: 1em;
    }
    li {
      margin: 8px 0;
      @media screen and (max-width: 800px) {
       display: inline-block;
       margin: 0px 5px;
      }
    }
  }

.btnGroup {
  margin: 5px;
  min-width: 200px;
}


</style>
