<template>
  <div class="modalDescription buyForm" v-if="!isSent">
    <h2 v-if="isContact">Contact artist</h2>
    <h2 v-if="isOrder">Confirm your order</h2>
    <form @submit.prevent="send()">
      <div class="form-row">
        <span v-if="isOrder">Order the work </span>
        <span v-if="isContact">Message about the work </span>
        <span>"<strong>{{ painting.name }}</strong>" by <strong>{{ painting.displayName }}</strong></span>
      </div>
      <div class="form-row">
        <label for="clientName">Name: </label>
        <input type="text" name="clientName" id="clientName" v-model="client.name">
        <transition name="fade">
          <div class="error" v-if="errors.name">{{ errors.name }}</div>
        </transition>
      </div>
      <div class="form-row">
        <label for="clientPhone">Phone: </label>
        <input type="text" name="clientPhone" id="clientPhone" v-model="client.phone">
      </div>
      <div class="form-row">
        <label for="clientEmail">Email: </label>
        <input type="text" name="clientEmail" id="clientEmail" v-model="client.email">
        <transition name="fade">
          <div class="error" v-if="errors.email">{{ errors.email }}</div>
        </transition>
      </div>
      <div class="form-row" v-if="isContact">
        <label for="clientMessage">Message: </label>
        <textarea name="clientMessage" id="clientMessage" v-model="client.message" />
        <transition name="fade">
          <div class="error" v-if="errors.message">{{ errors.message }}</div>
        </transition>
      </div>
      <div class="form-row">
        <captcha class="form-rowInput" @totalCreated="getAntiSpamTotal"/>
        <label for="controlInputNumber">Answer: </label>
        <input type="number" v-model.number="controlInputNumber" name="controlInputNumber" id="controlInputNumber">
        <transition name="fade">
          <div class="error" v-if="errors.antispam">{{ errors.antispam }}</div>
        </transition>
      </div>
      <buttonCustom type="submit" :button="'save'" :icon="'confirm'" :text="isOrder ? 'Place Order' : 'Send Message'"/>
      <buttonCustom type="reset" :button="'cancel'" :icon="'back'" :text="'Back'" @click.native="$emit('goBack')" />
    </form>
  </div>
  <transition name="fade" v-else>
    <div class="modalDescription buyForm alignCenter">
      <h1>Thank you!</h1>
      <br />
      <div v-if="isOrder">Your order has been sent!</div>
      <div v-else>Your message has been sent!</div>
    </div>
  </transition>
</template>


<script>
import Captcha from '@/components/Captcha'
import { db } from '@/firebaseInit'
import axios from 'axios'

export default {
  components: {
    'captcha': Captcha
  },
  name: 'paintingContact',
  props: {
    painting: Object,
    isOrder: Boolean,
    isContact: Boolean
  },
  data () {
    return {
      controlInputNumber: null,
      antiSpamNumber: null,
      errors: this.getFreshErrors(),
      client: this.getNewClient(),
      isSent: false
    }
  },
  mounted () {
    this.client = this.getNewClient()
    this.errors = this.getFreshErrors()
  },
  methods: {
    onCancel () {
      this.$emit('closeModal')
    },
    getAntiSpamTotal (val) {
      this.antiSpamNumber = val
    },
    getFreshErrors () {
      return {
        email: null,
        antispam: null,
        name: null,
        message: null
      }
    },
    getNewClient () {
      return {
        name: null,
        email: null,
        phone: null,
        message: null
      }
    },
    send () {
      this.errors = this.getFreshErrors()
      if (!this.client.name) {
        this.errors.name = 'Name required'
      }
      if (!this.client.email) {
        this.errors.email = 'Email required'
      }
      if (!this.client.message && this.isContact) {
        this.errors.message = 'The message cannot be empty!'
      }
      if (this.controlInputNumber !== this.antiSpamNumber) {
        this.errors.antispam = 'Not the right answer. Please try again'
      }
      if (!this.controlInputNumber) {
        this.errors.antispam = 'Please write the sum of the numbers above'
      }
      if (this.client.email && !this.validEmail(this.client.email)) {
        this.errors.email = 'Valid email please'
      }

      const formHasNoErrors = !Object.values(this.errors).some(x => (x !== null && x !== ''))

      if (formHasNoErrors) {
        let date = new Date()

        const toSend = {
          client: this.client,
          painting: this.painting,
          date: date,
          formattedDate: date.toLocaleDateString('ro-RO') + ' ' + date.toLocaleTimeString('ro-RO'),
          type: this.isOrder? 'order': 'contact',
          isRead: false,
        }

        if (!toSend.client.phone) {
          toSend.client.phone = 'Not filled in'
        }
        if (!toSend.client.message) {
          if (toSend.type === 'order') {
            toSend.client.message = 'Message not required'
          } else {
            toSend.client.message = 'Message not filled in'
          }
        }

        axios.post('./paintingform.php', toSend )
        db.collection('admins').doc(this.painting.userUid).get().then(doc => {
          if (doc.exists) {
          // recipient is admin
            db.collection('admins').doc(this.painting.userUid).collection('messages').add({
              ...toSend
            })
          .then(() => {
            console.log('message sent')
            this.isSent = true
          })
          .catch(err => console.log('error: ', err))
          } else {
            // must be just a user
            db.collection('users').doc(this.painting.userUid).collection('messages').add({
              ...toSend
            })
          .then(() => {
            console.log('message sent')
            this.isSent = true
          })
          .catch(err => console.log('error: ', err))
          }
        })

      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.modalDescription {
  flex: 1 0 35%;
  padding: 1em;
  margin-top: 30px;
  font-size: 1em;
  @media screen and (min-width: 800px) {
    flex: 1 0 30%;
  }
  * {
    font-size: 20px;
  }
  h1 {
    font-size: 2em;
    margin-bottom: 1em;
  }
  h2 {
    font-size: 1em;
  }
}


.button-w-icon {
  margin: 5px;
  min-width: 200px;
}

.buyForm {
  margin-top: 2em;
  form .form-row {
    margin: 15px auto;
    input, label, p {
      font-size: 16px;
    }
    p {
      font-size: 18px;
      line-height: 25px;
    }
  }
}


.error {
  font-size: 16px;
  color: red;
}

.alignCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: green;
}

</style>
